/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2025-03-13T03:24:25.421Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '25.1.10400-1751815';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
